"use client";
import { formatSeasonYear } from "@/utils";
import { Sport, formatEmptyState } from "common";
import React from "react";
import Button from "../primitives/button";
import Icon from "../primitives/icon";
import Link from "next/link";
import { useCountdown } from "@/utils/hooks/countdown";

interface LeagueKickoffProps {
  year: string;
  dateTime: string;
  sport: Sport;
  clientTime?: number;
}
export default function LeagueKickoff(props: LeagueKickoffProps) {
  const timeRemaining = useCountdown(props.dateTime, props.clientTime, true);
  let uniqueText: string = "";
  switch (props.sport) {
    case "nfl":
      uniqueText = "kicks off in";
      break;
    case "nba":
      uniqueText = "tips off in";
      break;
    case "mlb":
      uniqueText = "opening pitch in";
      break;
    case "nhl":
      uniqueText = "puck drops in";
      break;
  }
  return (
    <div className="pt-0 md:pt-3 px-5 h-[150px] w-full bg-[radial-gradient(100%_100%_at_50%_0%,_#222326_0%,_#131517_100%)] rounded-lg flex justify-center items-center flex-col relative overflow-hidden">
      <div className="w-[403px] h-[189px] -top-5 bg-contain bg-center absolute inset-0 left-0 bg-no-repeat z-0 opacity-30 md:opacity-100 block" style={{backgroundImage: `url('/images/game/${props.sport}-kickoff-logos.png')`}}></div>
      <div className="absolute inset-0 right-0 bg-right bg-[url('/images/game/league-kickoff-arrows.png')] bg-no-repeat z-0"></div>
      <div className="absolute right-12 top-0 bottom-0 items-center hidden md:flex">
        <Link href={`/${props.sport}`} className="btn btn-md btn-filled-primary"><span>Go to {props.sport}</span> <Icon name="caret-right" size="sm" /></Link>
        {/* <Button href={`/${props.sport}`}>Go to {props.sport} <Icon name="caret-right" size="sm" /></Button> */}
      </div>
      <div className="flex flex-row justify-between w-full md:w-auto">

      <div className="flex flex-col md:items-center">

      <div className="text-headline-bold md:text-headline-lg text-white uppercase mb-1">{formatSeasonYear(props.sport, props.year)} {props.sport} Season</div>
      <div className="text-label-sm-bold md:text-body-sm-medium uppercase text-secondary-500 mb-2">{uniqueText}</div>
      </div>
      <div className="block md:hidden">
      <Link href={`/${props.sport}`} className="btn btn-xs btn-filled-primary"><span>Go to {props.sport}</span> <Icon name="caret-right" size="xs" /></Link>

      {/* <Button href={`/${props.sport}`} size="xs">Go to {props.sport} <Icon name="caret-right" size="xs" className="mb-0.5" /></Button> */}
      </div>

      </div>
      <div className="flex w-full max-w-xs h-[74px] z-[1] bg-[url('/images/game/moneyline-countdown.png')] rounded-lg bg-contain bg-no-repeat bg-center justify-center items-center">
        <div className="basis-3/12 flex flex-col gap-[2px] justify-center items-center">
          <span className="text-display-lg text-white">
            {timeRemaining.days || <span className="text-secondary-500 text-label">{formatEmptyState("empty")}</span>}
          </span>
          <span className="text-white opacity-70 text-label font-bold uppercase">
            Days
          </span>
        </div>
        <div className="basis-3/12 flex flex-col gap-[2px] justify-center items-center">
          <span className="text-display-lg text-white">
            {timeRemaining.hours || <span className="text-secondary-500 text-label">{formatEmptyState("empty")}</span>}
          </span>
          <span className="text-white opacity-70 text-label font-bold uppercase">
            Hrs
          </span>
        </div>
        <div className="basis-3/12 flex flex-col gap-[2px] justify-center items-center">
          <span className="text-display-lg text-white">
            {timeRemaining.minutes || <span className="text-secondary-500 text-label">{formatEmptyState("empty")}</span>}
          </span>
          <span className="text-white opacity-70 text-label font-bold uppercase">
            Minutes
          </span>
        </div>
        <div className="basis-3/12 flex flex-col gap-[2px] justify-center items-center">
          <span className="text-display-lg text-white">
            {timeRemaining.seconds || <span className="text-secondary-500 text-label">{formatEmptyState("empty")}</span>}
          </span>
          <span className="text-white opacity-70 text-label font-bold uppercase">
            Seconds
          </span>
        </div>
      </div>
    </div>
  );
}
