"use client";
import React from 'react';

interface DateInfo {
  date: Date | null;
  timeZone: string;
}

export function useLocalDate(initialDateIsoString?: string) {
  
  const getDateInfo = (isoString: string): DateInfo => {
    const date = isoString ? new Date(isoString) : new Date();
    const options: Intl.DateTimeFormatOptions = { timeZoneName: 'short' };
    const dateStringWithZone = new Intl.DateTimeFormat('en-US', options).format(date);
    const timeZoneMatch = dateStringWithZone.match(/\b([A-Z]+)\b$/);
    const timeZoneAbbreviation = timeZoneMatch ? timeZoneMatch[0] : 'UTC';

    return {
      date,
      timeZone: timeZoneAbbreviation
    };
  };

  const [dateInfo, setDateInfo] = React.useState<DateInfo>(() => getDateInfo(initialDateIsoString ? initialDateIsoString : new Date().toISOString()));

  React.useEffect(() => {
    setDateInfo(getDateInfo(initialDateIsoString ? initialDateIsoString : new Date().toISOString()));
  }, [initialDateIsoString]);

  return dateInfo;
}
