import { CurrentSeason, Sport } from "common";



export interface SportMetaData {
  id: string;
  label: string;
  icon: string;
}

export type Season = NFLSeason | NBASeason | MLBSeason | NHLSeason;

export enum NFLSeason {
  Preseason = "Pre Season",
  RegularSeason = "Regular Season",
  Playoffs = "Playoffs",
  SuperBowl = "Super Bowl",
}

export enum NBASeason {
  Preseason = "Pre Season",
  RegularSeason = "Regular Season",
  Playoffs = "Playoffs",
  Finals = "NBA Finals",
}

export enum MLBSeason {
  SpringTraining = "Spring Training",
  RegularSeason = "Regular Season",
  PostSeason = "Post Season",
}

export enum NHLSeason {
  Preseason = "Pre Season",
  RegularSeason = "Regular Season",
  Playoffs = "Playoffs",
  Finals = "Stanley Cup Finals",
}

export const SPORTS: SportMetaData[] = [
  { id: "nfl", label: "NFL", icon: "football" },
  { id: "nba", label: "NBA", icon: "basketball" },
  { id: "mlb", label: "MLB", icon: "baseball" },
  { id: "nhl", label: "NHL", icon: "hockey-puck" },
];

export const COMING_SOON_SPORTS: SportMetaData[] = [
  { id: "soccer", label: "Soccer", icon: "soccer" },
  { id: "ncaaf", label: "NCAAF", icon: "football" },
  { id: "ufc", label: "UFC", icon: "ufc" },
  { id: "ncaab", label: "NCAAB", icon: "basketball" },
  { id: "boxing", label: "Boxing", icon: "boxing" },
  { id: "wnba", label: "WNBA", icon: "basketball" },
  { id: "golf", label: "Golf", icon: "golf" },
  { id: "cfl", label: "CFL", icon: "football" },
];

export function getSportData(sport: Sport) {
  return null;
}

export function isSport(value: string): boolean {
  return (
    value === "nfl" || value === "nba" || value === "mlb" || value === "nhl"
  );
}

export const SPORT_OPTIONS: Option[] = [
  ...SPORTS.map((sport, i) => ({
    value: sport.id,
    label: sport.label,
    icon: sport.icon,
  })),
];

export const ALL_SPORT_OPTIONS: Option[] = [
  {
    value: "all-sports",
    label: "All Sports",
  },
  ...SPORTS.map((sport, i) => ({
    value: sport.id,
    label: sport.label,
    icon: sport.icon,
  })),
];

type GameCounts = Record<Sport, number>;

export function createSportsOptions(
  counts?: GameCounts,
  detailed?: boolean
): Option[] {
  return [
    {
      label: detailed ? "All Sports" : "All",
      value: "all",
      count: counts ? counts.all : 0,
    },
    ...SPORTS.map((sport, i) => ({
      value: sport.id,
      label: sport.label,
      icon: sport.icon,
      count: counts ? counts[sport.id as Sport] ?? 0 : 0,
    })),
  ];
}

export function getSeasonOptions(season: number): Option[] {
  switch (season) {
    case 3:
    case 4:
      return [
        { label: "Preseason", value: "preseason" },
        { label: "Regular Season", value: "regular-season" },
        { label: "Postseason", value: "postseason" },
      ];
    case 1:
      return [
        { label: "Preseason", value: "preseason" },
        { label: "Regular Season", value: "regular-season" },
      ]
    case 2:
      return [
        { label: "Preseason", value: "preseason" },
      ]
    default:
      return [
        { label: "Preseason", value: "preseason" },
        { label: "Regular Season", value: "regular-season" },
        { label: "Postseason", value: "postseason" },
      ];
  }
}


export async function getCurrentSeason(
  sport: Sport,
  year?: string,
  team?: string
): Promise<CurrentSeason> {
  const baseUrl: string = `${
    process.env.NEXT_PUBLIC_API_URL
  }/api/sports/${sport}/current-season`;
  const params: URLSearchParams = new URLSearchParams();
  if (year) {
    params.append("year", year);
  }
  if (team) {
    params.append("team", team);
  }
  const request = `${baseUrl}?${params}`;
  const res = await fetch(
    request,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      cache: "no-store",
    }
  );
  if (!res.ok) {
    throw new Error("Failed to fetch current season");
  }
  return res.json();
}

export async function getCurrentSeason2(
  sport: Sport,
  year?: string,
  team?: string,
  active?: boolean,
): Promise<CurrentSeason2> {
  const baseUrl: string = `${
    process.env.NEXT_PUBLIC_API_URL
  }/api/sports/${sport}/current-season2`;
  const params: URLSearchParams = new URLSearchParams();
  if (year) {
    params.append("year", year);
  }
  if (team) {
    params.append("team", team);
  }
  if (active) {
    params.append("active", `true`);
  }
  const request = `${baseUrl}?${params}`;
  const res = await fetch(
    request,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      cache: "no-store",
    }
  );
  if (!res.ok) {
    throw new Error("Failed to fetch current season");
  }
  return res.json();
}

export function determineSeasonYear(currentSeason: CurrentSeason): number {
  if (currentSeason.past) {
    return currentSeason.past.year;
  }
  switch (currentSeason.current.season) {
    case 4:
      return currentSeason.current.year - 1;
    default:
      return currentSeason.current.year;
  }
}